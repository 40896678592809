// export const closedDateRanges = [
//   {start: new Date('2023-12-01'), end: new Date('2024-06-21')},
//   {start: new Date('2024-09-16'), end: new Date('2025-06-20')},
//   {start: new Date('2024-09-16'), end: new Date('2099-01-01')},
// ]

export const closedDateRanges = [
  {
    start: new Date("2024-09-01T00:00:00Z"),
    end: new Date("2025-06-20T23:59:59Z"),
  },
  {
    start: new Date("2025-09-14T00:00:00Z"),
    end: new Date("2099-01-01T23:59:59Z"),
  },
]
